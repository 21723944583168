import axios from 'axios';

// let axiosConfig = {
//     headers: {
//         'Content-Type': 'application/json;charset=UTF-8',
//         "Access-Control-Allow-Origin": "*",
//     }
// };


function dashboardPropertyList(params) {
    return axios.get(`${process.env.API_IMAGE_URL}property`, { params });
}
function dashboardPropertyDetail(id, params) {
    return axios.get(`${process.env.API_IMAGE_URL}property/` + id, { params });
}
function claimPropertyList(params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/claim`, { params });
}
export function unclaimPropertyList(id) {
    return axios.put(`${process.env.API_IMAGE_URL}v1/Claim/${id}`)
}
function findAgentList(params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/agent`, { params });
}
function findAgentListDuplicate(params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/dup-agent`, { params });
}
function findAgentFeatureList(params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/featured-agent`, { params });
}
export function imageLikeList(id) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/favorite-property/${id}`);
}
export function imageLike(id, params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/favorite-property/${id}?property_id=${params}`);
}
export function intrestedPropertyDetail(id) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/favorite-property/${id}?populate=property_id`);
}
export function sellerProspectList(params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/seller-prospect`, { params });
}
export function sendRequestToAgent(params) {
    return axios.post(`${process.env.API_IMAGE_URL}v1/agent`, params);
}
export function buyerProspectList(params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/buyer-prospect`, { params });
}
export function profileView(id) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/user/${id}`);
}
export function agentAccept(id) {
    return axios.put(`${process.env.API_IMAGE_URL}v1/prospect/${id}`, { "status": "accept" });
}
export function agentDecline(id) {
    return axios.put(`${process.env.API_IMAGE_URL}v1/prospect/${id}`, { "status": "decline" });
}
export function buyerList(params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/buyer-prospect`, { params });
}
export function sellerList(params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/seller-prospect`, { params });
}
export function similarProperty(id, params) {
    return axios.get(`${process.env.API_IMAGE_URL}similar-property/${id}`, { params });
}
export function termsCondition(params) {
    return axios.get(`${process.env.API_IMAGE_URL}page/${params}`);
}
export function privacyPolicy(params) {
    return axios.get(`${process.env.API_IMAGE_URL}page/${params}`);
}
export function profileUpdate(uid) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/fire-user/${uid}`);
}
export function propertyActivity(id, params) {
    return axios.get(`${process.env.API_IMAGE_URL}property-activity/${id}`, { params });
}
export function stateList(params) {
    return axios.get(`${process.env.API_IMAGE_URL}v2/state`, params);
}
export function claimAgentList(id, params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/my-agent/${id}`, { params });
}
export function getAllAgents(params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/agent`, { params });
}
export function favoritePicture(user_id, params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/property-img-fav/${user_id}`, { params });
}
export function sendPushNotification(params) {
    return axios.post(`${process.env.API_IMAGE_URL}push-notification`, params);
}
export function inboundList(id, params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/agent-inbound/${id}`, { params });
}
export function outboundList(id, params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/agent-outbound/${id}`, { params });
}
export function upDateReferralFee(id, params) {
    return axios.put(`${process.env.API_IMAGE_URL}v1/referral-update/${id}`, params);
}
export function uploadFile(params) {
    return axios.post(`${process.env.API_IMAGE_URL}uploadFile?folder=user_image/`, params);
}
export function uploadPropertyFile(params) {
    return axios.post(`${process.env.API_IMAGE_URL}uploadFile?folder=property_image/`, params);
}
export function fileUpload(params) {
    return axios.post(`${process.env.API_IMAGE_URL}uploadFile?folder=others/`, params);
}
export function rocommendedProperty(id, params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/recommended/${id}`, { params });
}
export function appointmentDetail(id, params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/appointment/${id}`, params);
}
export function createSchedule(params) {
    return axios.post(`${process.env.API_IMAGE_URL}v1/staff`, params);
}
export function getScheduler(id) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/staff/${id}`);
}
export function updateScheduler(id, params) {
    return axios.put(`${process.env.API_IMAGE_URL}v1/staff/${id}`, params);
}
export function getAvailableTime(params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/availableTimes`, { params });
}
export function notificationList(id, params) {
    return axios.get(`${process.env.API_IMAGE_URL}push-notification/${id}`, { params });
}
export function notificationDelete(id) {
    return axios.post(`${process.env.API_IMAGE_URL}delete-notification`, id);
}
export function agentReadNotification(id) {
    return axios.put(`${process.env.API_IMAGE_URL}push-notification`, id);
}
export function listedProperty(id, params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/listedproperties/${id}`, { params })
}
export function scheduleAppointment(params) {
    return axios.post(`${process.env.API_IMAGE_URL}v1/appointment`, params);
}
export function tourList(params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/tour`, { params });
}
export function updateProfile(id, params) {
    return axios.put(`${process.env.API_IMAGE_URL}v1/user/${id}`, params);
}
export function updateAppoinment(id, params) {
    return axios.put(`${process.env.API_IMAGE_URL}v1/appointment/${id}`, params);
}
export function saveUploadFile(id, params) {
    return axios.put(`${process.env.API_IMAGE_URL}v1/fileurl/${id}`, params);
}
export function deleteAppoinment(id, params) {
    return axios.delete(`${process.env.API_IMAGE_URL}v1/appointment/${id}`, { params });
}
export function recentltySaved(id, params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/favorite-property-recently/${id}`, { params });
}
export function mlsAgentSearch(params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/mls-agent`, { params });
}
export function sentOtp(params) {
    return axios.post(`${process.env.API_IMAGE_URL}v1/send-otp`, params);
}
export function verifyOtp(params) {
    return axios.post(`${process.env.API_IMAGE_URL}v1/verify-otp`, params);
}
export function PropertyStatus(params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/property-status`, params);
}
export function updateProperty(id, params) {
    return axios.put(`${process.env.API_IMAGE_URL}v1/property-home/${id}`, params);
}
export function updatePropertyStatus(id, params, payload) {
    return axios.put(`${process.env.API_IMAGE_URL}v1/property/${id}?agent_id=${params}`, payload);
}

export function exclusiveProperty(params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/exclusive-property`, { params });
}
export function featuredProperty(params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/featured-property`, { params });
}
export function tourListingApi(id, params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/tour-listing/${id}`, { params })
}
export function tourBuyerApi(id, params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/tour-buyer/${id}`, { params })
}
export function favoritePropertyDetails(id, params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/favorite-property-detail/${id}`, params);
}
export function recentlyViewedProperty(id, params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/recently-view-property/${id}`, params);
}
export function recentlyTouredProperty(id, params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/recently-tour-property/${id}`, params);
}
export function createGroup(params) {
    return axios.post(`${process.env.API_IMAGE_URL}v1/group`, params);
}
// export function tourBuyerListingApi(id,params) {
//     return axios.get(`${process.env.API_IMAGE_URL}v1/tour-buyer/${id}`,{ params })
// }
export function NetworkApi(id, params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/my-group/${id}`, { params });
}
export function unJoin(params) {
    return axios.post(`${process.env.API_IMAGE_URL}v1/un-join`, params);
}
export function deleteGroup(id, params) {
    return axios.put(`${process.env.API_IMAGE_URL}v1/group/${id}`, params);
}
export function inviteMember(params) {
    return axios.post(`${process.env.API_IMAGE_URL}v1/invite-members`, params);
}

export function groupMembers(id, params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/group/${id}`, params);
}
export function deleteGroupMember(id, params) {
    return axios.put(`${process.env.API_IMAGE_URL}v1/group-user/${id}`, params);
}
export function addGroupMember(id, params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/new-member${id}`, params);
}
export function inviteGroupList(id, params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/invite-list/${id}`, { params });
}
export function acceptInviteRequest(params) {
    return axios.post(`${process.env.API_IMAGE_URL}v1/accept-reject`, params);
}
export function joinGroupList(id, params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/join-group/${id}`, { params });
}
export function getAgentList(id, params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/agent-list/${id}`, params);
}
export function getAgentLists(id, params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/agent-list/${id}`, { params });
}
export function getSocialIcons(params) {
    return axios.get(`${process.env.API_IMAGE_URL}setting`, params);
}
export function deleteFile(params) {
    return axios.post(`${process.env.API_IMAGE_URL}delete`, params);
}
export function getGroupList(id, params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/group-list/${id}`, { params });
}
export function joinGroup(id, params) {
    return axios.put(`${process.env.API_IMAGE_URL}v1/join-group/${id}`, params);
}
export function getOffers(id, params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/property-offer/${id}`, params);
}
export function getParticularOffer(id, params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/offer/${id}`, params);
}
export function createOffers(params) {
    return axios.post(`${process.env.API_IMAGE_URL}v1/offer`, params);
}
export function getBanners(params) {
    return axios.get(`${process.env.API_IMAGE_URL}banner`, { params });
}
export function updateGroupName(id, params) {
    return axios.put(`${process.env.API_IMAGE_URL}v1/group-name/${id}`, params);
}
export function reportMessage(params) {
    return axios.post(`${process.env.API_IMAGE_URL}v1/report-message`, params);
}
export function buyerTouredList(id, params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/buyer-tour-property/${id}`, params);
}
export function buyerPropertyDetail(id, params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/buyer-property/${id}`, params);
}
export function upCommingTourList(id, params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/upcoming-tour/${id}`, params);
}
export function NotifyIntrested(id, params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/offer-notify/${id}`, params);
}
export function buyerAgentRequest(params) {
    return axios.post(`${process.env.API_IMAGE_URL}v1/buyer-agent`, params);
}
export function buyerTourActivity(id, params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/buyer-tour-activity/${id}`, { params });
}
export function buyerTourCount(id, params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/buyer-tour-count/${id}`, { params });
}
export function demandCalculator(params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/demand-calculator`, { params });
}
export function suggestedPrice(id, params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/suggested-price/${id}`, params);
}
export function recentlySold(id, params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/recently-sold-comparable/${id}`, { params });
}
export function viewershipOffer(id, params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/view-down-offer/${id}`, params);
}
export function updateHomeDetails(id, params) {
    return axios.put(`${process.env.API_IMAGE_URL}v1/property-home/${id}`, params);
}
export function checkingOffer(id, params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/check-property-offer/${id}`, { params });
}
export function buyerGroupList(id, params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/group-buyer-prospect/${id}`, { params });
}
export function tagList(params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/property-img-name`, { params });
}
export function tourlistingDetails(id, params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/group-tour-listing/${id}`, { params });
}
export function reportDisclosure(params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/request-view_signed_disclosure`, { params });
}
export function resetPassword(params) {
    return axios.post(`${process.env.API_IMAGE_URL}reset-password`, params);
}
export function unclaimAgent(id, params) {
    return axios.put(`${process.env.API_IMAGE_URL}v1/un-claimed-agent/${id}?agent_id=${params.agent_id}`);
}
export function deleteLicenceNumber(uid, params) {
    return axios.put(`${process.env.API_IMAGE_URL}v1/delete-user-licenseNumber/${uid}`, params);
}
export function deleteUser(uid) {
    return axios.put(`${process.env.API_IMAGE_URL}v1/delete-user/${uid}`);
}
export function manageToursdetails(id) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/tour-property/${id}`);
}
export function CreateCBagents(params) {
    return axios.post(`${process.env.API_IMAGE_URL}v2/create-broker`, params);
}
export function agentUnavailable(params) {
    return axios.post(`${process.env.API_IMAGE_URL}v1/contact-request`, params);
}
export function updatePassword(params) {
    return axios.put(`${process.env.API_IMAGE_URL}v2/userspassword`, params);
}
export function joinGroupPendingList(params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/join-group`, { params });
}
export function propertyimage(params) {
    return axios.get(`${process.env.API_IMAGE_URL}propertyimag`, { params });
}
export function attomAvmData(params) {
    return axios.get(`${process.env.API_IMAGE_URL}attomproperty`, { params });
}
export function claimStatus(params) {
    return axios.get(`${process.env.API_IMAGE_URL}verifyclaim`, { params });
}
export function updateImages(id, params) {
    return axios.put(`${process.env.API_IMAGE_URL}updateimage/${id}`, params);
}
export function cityCoordinates(params) {
    return axios.get(`${process.env.API_IMAGE_URL}cityCoordinates`, { params });
}
export function cityNames(params) {
    return axios.get(`${process.env.API_IMAGE_URL}cityNames`, { params });
}
export function agentContactRequest(params) {
    return axios.post(`${process.env.API_IMAGE_URL}v1/property-agentcontact-request`, params);
}
export function agentNameSearch(params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/name-search`, { params });
}
export function addressSearch(params) {
    return axios.get(`${process.env.API_IMAGE_URL}/getAddress`, { params });
}
export function myLeads(params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/my-leads`, { params });
}
export function addProperty(id, params) {
    return axios.post(`${process.env.API_IMAGE_URL}v2/agent-property?agent_id=${id}`, params);
}
export function EditPropertyMylist(propertyId, agent_id, payload) {
    return axios.put(`${process.env.API_IMAGE_URL}v2/property-home/${propertyId}?agent_id=${agent_id}`, payload);
}
export function createScheduleTime(params) {
    return axios.post(`${process.env.API_IMAGE_URL}v1/createStaffWorkingHours`, params);
}
export function userList(params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/user`, { params });
}
export function blogList(params) {
    return axios.get(`${process.env.API_IMAGE_URL}getAllBlogsForUsers`, { params });
}
export function blogCityList(params) {
    return axios.get(`${process.env.API_IMAGE_URL}getAllPageForUser`, { params });
}
export function getScheduleTime(id) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/getstaff?id=${id}`);
}
export function CityLatLng(params) {
    return axios.get(`${process.env.API_IMAGE_URL}CityLatLong`, { params });
}
export function updateScheduleTime(id, params) {
    return axios.put(`${process.env.API_IMAGE_URL}v1/updatestaff?id=${id}`, params);
}
export function getTourTime(params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/avaiableslots`, { params });
}
export function scheduleTourTime(params) {
    return axios.post(`${process.env.API_IMAGE_URL}v1/createAppointment`, params);
}
export function updateTourTime(id, params) {
    return axios.put(`${process.env.API_IMAGE_URL}v1/updateappionment?id=${id}`, params);
}
export function deleteTourTime(params) {
    return axios.delete(`${process.env.API_IMAGE_URL}v1/appointment`, { params });
}
export function singleBlog(params) {
    return axios.get(`${process.env.API_IMAGE_URL}getBlog?page_url=${params}`);
}
export function singleCity(params) {
    return axios.get(`${process.env.API_IMAGE_URL}getPage?page_url=${params}`);
}
export function roleCheck(params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/role-check?email=${params}`);
}
export function getCityProperties(params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/city-properties?city=${params}`);
}
export function claimCheckDetails(id) {
    return axios.get(`${process.env.API_IMAGE_URL}verifyaddress?id=${id}`);
}
export function attomParcel(params) {
    return axios.get(`${process.env.API_IMAGE_URL}v2/AttomtaxBoundryLocation`, { params });
}
export function attomBoundry(params) {
    return axios.get(`${process.env.API_IMAGE_URL}v2/boundry`, { params });
}
export function getNotificationCount(id) {
    return axios.get(`${process.env.API_IMAGE_URL}notification/${id}`);
}
export function getBoundaryGraphql(params) {
    return axios.post(`${process.env.APP_URL}/graphql`, params);
}
export function addressCheck(params) {
    return axios.post(`${process.env.API_IMAGE_URL}v2/addressvalidate`, params);
}
export function GetSplitAddresss(params) {
    return axios.get(`${process.env.API_IMAGE_URL}v2/python-address`, { params });
}
export function leadsAdding(params) {
    return axios.post(`${process.env.API_IMAGE_URL}v1/agent-request`, params);
}
export function newAtlasSearch(params) {
    return axios.get(`${process.env.API_IMAGE_URL}v2/autocompleteAddress`, { params });
}
export function addRating(params) {
    return axios.post(`${process.env.API_IMAGE_URL}v1/rate`, params);
}
export function getRating(params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/rateall`, { params });
}
export function agentProperty(params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/agent-fulldetails`, { params });
}
export function screenName(params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/screen_name`, { params });
}
export function savedSearchAll(params) {
    return axios.get(`${process.env.API_IMAGE_URL}v2/searchall`, { params });
}
export function savedSearchdashboard(params) {
    return axios.post(`${process.env.API_IMAGE_URL}v2/save`, params);
}
export function savedSearchDeleteApi(params) {
    return axios.delete(`${process.env.API_IMAGE_URL}v2/deletesearch`, { params });
}
export function savedAgentList(params) {
    return axios.delete(`${process.env.API_IMAGE_URL}v2/deletesearch`, { params });
}
export function editSearch(id, params) {
    return axios.put(`${process.env.API_IMAGE_URL}v2/editSearch?id=${id}`, params);
}
export function ShareSearch(id, params) {
    return axios.post(`${process.env.API_IMAGE_URL}v2/sharesearch?id=${id}`, params);
}
export function agentSharelists(params) {
    return axios.get(`${process.env.API_IMAGE_URL}v2/userlists`, { params });
}
export function brokerageNameApi(params) {
    return axios.get(`${process.env.API_IMAGE_URL}v2/office`, { params });
}
export function broadcastInternalAgent(id) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/boardCast?office_id=${id}`);
}
export function broadcastAllAgent(params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/boardCast?name`, { params });
}
export function broadcastApi(params) {
    return axios.post(`${process.env.API_IMAGE_URL}v1/broadcast`, params);
}
export function coldWellCompany(params) {
    return axios.get(`${process.env.API_IMAGE_URL}office`, { params });
}
export function broadCastListApi(params) {
    return axios.get(`${process.env.API_IMAGE_URL}v1/broadcastlist`, { params })
}
export function sellerBroadcastApi(params){
    return axios.post(`${process.env.API_IMAGE_URL}v1/sellerbroadcast`,params)
}
export function agentBrokerageSearch(param1,params) {
    return axios.get(`${process.env.API_IMAGE_URL}brokerageoffice?office_name=${param1}&office_address=${params}`);
}
export {
    dashboardPropertyList,
    dashboardPropertyDetail,
    claimPropertyList,
    findAgentList,
    findAgentFeatureList, 
    findAgentListDuplicate
}






