import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { dashboardPropertyDetail, imageLikeList } from "../components/Api/ListingApi";

const name = "detail";
const initialState = {
  loading: false,
  error: null,
  result: {},
};

const fetchdetail = createAsyncThunk("detail/fetchdetail", async (id) => {
  let userId = localStorage.getItem('userId')

  let getParams = {
    user_id: userId
  }
  const response = await dashboardPropertyDetail(id,getParams);
  response.data.data.images.forEach((item) => {
    let isLike = item.likes.indexOf(userId)
    let isDisLike = item.dis_likes.indexOf(userId)
    if (isLike !== -1) {
      item.isLike = true
    }
    if (isDisLike !== -1) {
      item.isDisLike = true
    }
  })
  var result = {}
  if (userId !== null) {
    await imageLikeList(userId).then((res) => {
      var number = res.data.data.indexOf(response.data.data._id)
      if (number !== -1) {
        let data = {
          isLike: res.data.data[number]
        }
        let value = response.data.data
        result = { ...data, ...value }
      }
      else {
        result = response.data.data
      }
    }).catch((error)=>
    {
      console.log(error)
    })
  }
  else {
    result = response.data.data
  }
  // console.log('redux result', result);
  return result;
});

const detailSlice = createSlice({
  name,
  initialState,
  extraReducers: {
    [fetchdetail.pending]: state => {
      if (!state.loading) {
        state.loading = true;
      }
    },
    [fetchdetail.fulfilled]: (state, action) => {
      // console.log('fetchdetail fulfilled state', state);
      // console.log('fetchdetail fulfilled action', action.payload);
      if (state.loading) {
        state.loading = false;
        // console.log('redux fetchdetail', action.payload);

        state.result = action.payload;
      }
    },
    [fetchdetail.rejected]: (state, action) => {
      if (state.loading) {
        state.loading = false;
        state.error = action.payload;
      }
    }
  },
  reducers: {
    singlePropertyClear: state => {
      state.result = {}
    }, 
    storeResult: (state, action) => {
  // console.log('redux storeResult', action.payload);

      // console.log('fetchdetail storeResult action', action.payload);
      state.result = action.payload
  },
  }
});

export { fetchdetail };
export const detail = name;
export const { singlePropertyClear,storeResult } = detailSlice.actions
export const detailReducer = detailSlice.reducer;
