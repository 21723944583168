import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    modalDetails: {
        display: false,
        lastShown: '',
    }
};

export const modalSlice=createSlice({
    name:"modal",
    initialState,
    reducers:{
        handleModalPopupShow: (state, action) => {
            // console.log('app.js redux handleModalPopupShow')
            state.modalDetails =  { ...state.modalDetails, display: true, lastShown: new Date() }
        },
        handleModalPopupClose: (state, action) => {
            // console.log('app.js redux handleModalPopupClose')
            state.modalDetails =  { ...state.modalDetails, display: false }
        }
    }
})

export const {handleModalPopupShow, handleModalPopupClose} = modalSlice.actions;
export const modalReducer = modalSlice.reducer;