import React from 'react';
import { Provider } from 'react-redux';
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import { PersistGate } from 'redux-persist/integration/react';
import userReducer from "./userSlice";
import { detail, detailReducer } from "./detailSlice";
import calenderResponse from './calenderPopUp';
import { claimAgentLists } from './claimAgentList';
import { getAllAgent } from './getAllAgent';
import { dashboardReducer } from './dashboardViewAction';
import { modalReducer } from './modalReducer';
import createTransform from 'redux-persist/es/createTransform';


const combinedReducer = combineReducers({
  user: userReducer,
  [detail]: detailReducer,
  calender: calenderResponse,
  claimAgent: claimAgentLists,
  getAgent: getAllAgent,
  dashboard: dashboardReducer,
  modal: modalReducer,
});

// const rootReducer = (state, action) => {
//   if (action.type === ) {
//     // for all keys defined in your persistConfig(s)
//     storage.removeItem('persist:root')
//     // storage.removeItem('persist:otherKey')

//     return appReducer(undefined, action);
// }
// return appReducer(state, action);
// }


const rootReducer = (state, action) => {
  if (action.type === 'user/logout') {
    storage.removeItem('persist:root')
    state = undefined;
  }
  return combinedReducer(state, action);
};
const SetTransform = createTransform(
  // transform state on its way to being serialized and persisted.
  (inboundState, key) => {
    // convert mySet to an Array.
    return { ...inboundState, mySet: [...inboundState.mySet] };
  },
  // transform state being rehydrated
  (outboundState, key) => {
    // convert mySet back to a Set.
    return { ...outboundState, mySet: new Set(outboundState.mySet) };
  },
  // define which reducers this transform gets called for.
  { whitelist: ['someReducer'] }
);
 
// export default SetTransform

const persistConfig = {
  key: 'root',
  storage: storage,
  transforms: [SetTransform]
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

// const createStore = () => configureStore({
//   reducer: rootReducer
// });

const store = configureStore({
  reducer: persistedReducer,
  // devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
});

let persistor = persistStore(store);

export default ({ element }) => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      {element}
    </PersistGate>
  </Provider>
);
